<template>
  <div
    class="card p-shadow-6 p-col-12 p-md-6 p-md-offset-3"
    :style="{ position: 'relative', minHeight: '200px' }"
  >
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <h1>Classificação Formação</h1>
    <div v-if="info.training != undefined">
      <p>
        Caro(a) {{ loggedUser.username }}, agradecemos a sua participação na
        formação <b>{{ info.training.name }}</b
        >.
      </p>
      <p v-if="!info.user_classification">
        Agradecemos que nos dê a sua opinião sobre a formação.
      </p>
      <p v-else>Agradecemos a sua opinião sobre a formação.</p>
      <label for="evaluation"><b>Classificação</b></label>
      <Rating
        name="evaluation"
        class="p-mt-3"
        v-model="evaluation"
        :cancel="false"
        :disabled="info.user_classification != null"
      />
      <small v-if="hasError == true" class="p-error" role="alert">
        Não classificou a formação.
      </small>
      <br />
      <label for="comments" class="p-mt-4"><b>Comentários</b></label>
      <br />
      <Textarea
        name="comments"
        v-model="comments"
        class="p-mt-2 p-md-12"
        :autoResize="true"
        rows="5"
        :disabled="info.user_classification != null"
      />
      <br />
      <Button
        v-if="info.user_classification == null"
        label="Enviar Classificação"
        class="p-mt-2"
        @click="sendTrainingClassification()"
      />
      <small v-else>
        Respondido no dia
        {{ info.user_classification_date }}
      </small>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import trainingService from "../services/training.service";
export default {
  name: "TrainingUserEvaluation",
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      hasError: false,
      evaluation: null,
      comments: null,
      info: {},
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    await this.getInfo();
    this.evaluation = this.info.user_classification;
    this.comments = this.info.user_classification_comment;
  },
  methods: {
    async getInfo() {
      this.loading = true;
      this.info = await trainingService.getUserEvaluationTrainingInfo(
        this.$route.params.trainingId,
        this.$route.params.userId
      );
      return (this.loading = false);
    },
    sendTrainingClassification() {
      if (this.evaluation == null) {
        this.hasError = true;
        return;
      }
      this.hasError = false;
      let params = {
        evaluation: this.evaluation,
        comments: this.comments,
      };
      trainingService
        .sendTrainingClassification(
          this.info.training_action_id,
          this.info.user_id,
          params
        )
        .then((response) => {
          if (!response) {
            this.$toast.add({
              severity: "error",
              summary: "Erro ao enviar resposta",
              detail: "",
              life: 3000,
            });
          }
          return this.getInfo();
        });
    },
  },
};
</script>
